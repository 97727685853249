<template>
  <div class="modal" id="loanSyncInfo">
    <div class="modal-bg" @click="closeModal"></div>

    <ModalSpinner v-if="ui.loading"></ModalSpinner>

    <div class="modal-block" v-if="!ui.loading">

      <div class="modal-header d-block">
        <div class="d-flex justify-content-between align-items-center">
          <h3>
            Other information - {{ loan.borrowerName }}
          </h3>
          <button class="btn btn-control" @click="$emit('editApplication')">
            <img src="@/assets/icons/icon-edit-normal.svg" alt="Edit">
          </button>
        </div>
      </div>

      <div class="modal-body" id="modal-body-scroll">
        <div class="alert alert-danger" v-if="ui.isError">
          {{ ui.error }}
        </div>
        <div class="modal-body-container mt-3">
          <!-- <div class="row" v-if="this.loan.lender.name === 'UWM'">
            <div class="col-6">
              <div class="d-flex justify-content-center align-items-center">
                <div class="form-group">
                  <label for="fieldLoanNumber">Sync with loan number</label>
                  <input id="fieldLoanNumber" type="number" class="form-control" v-model="loanNumber" placeholder="XXXXXX">
                </div>
                <button type="submit" class="btn bg-green submit mx-2 mt-auto mb-1" @click="updateLoan">
                  <span v-if="ui.form.loading"
                        class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span v-if="!ui.form.loading">Sync</span>
                </button>
              </div>              
            </div>
            <div class="col-6 text-right">
              <b>Last Updated By UWM:</b> {{loan.loanUpdatedDate ? formatDate(loan.loanUpdatedDate, "MMM D, Y hh:mm A")  : 'N/A'}}
            </div>
            <hr class="mt-5">
          </div> -->
          <div class="table mt-4">
            <table class="table">
              <tbody>
              <tr>
                <td>Loan status</td>
                <td>{{ info.loanStatus }}</td>
              </tr>
              <tr>
                <td>Closing date</td>
                <td>{{ info.closingDate }}</td>
              </tr>
              <tr>
                <td>Lock date</td>
                <td>{{ info.lockDate }}</td>
              </tr>
              <tr>
                <td>Loan expiration date</td>
                <td>{{ info.loanExp }}</td>
              </tr>
              <tr>
                <td>Disbursement date</td>
                <td>{{ info.disbursementDate }}</td>
              </tr>
              <tr>
                <td>Submitted to UW date</td>
                <td>{{ info.submittedToUwDate }}</td>
              </tr>
              <tr>
                <td>Closing disclosure date</td>
                <td>{{ info.cdDate }}</td>
              </tr>
              <tr>
                <td>Funded date</td>
                <td>{{ info.fundedDate }}</td>
              </tr>
              <tr>
                <td>Wire approved date</td>
                <td>{{ info.wireApprovedDate }}</td>
              </tr>
              <tr>
                <td>Signed docs uploaded date</td>
                <td>{{ info.signedDocsUploadedDate }}</td>
              </tr>
              <tr>
                <td>Wire review</td>
                <td>{{ info.wireReview }}</td>
              </tr>
              <tr>
                <td>Loan Amount</td>
                <td>{{ info.loanAmount }}</td>
              </tr>
              <tr>
                <td>Appraisal Value</td>
                <td>{{ info.appraisedValue }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar'
import ModalSpinner from "../../components/modal/ModalSpinner";

export default {
  name: "LoanSyncInfoModal",
  components: {ModalSpinner},
  props: {
    loan: Object
  },
  data() {
    return {
      loanNumber: this.loan.loanNumber,
      info: null,
      ui: {
        loading: false, 
        isError: false, 
        error: '', 
        ps: null,
        form: {
          loading: false
        }
      }
    }
  },
  created() {
    this.ui.loading = true
    this.$http.get('/api/v1/loans/sync/info/' + this.loan.id)
      .then((response) => {
        this.loan.loanNumber = response.data.loan.loanNumber
        this.info = response.data.info
        this.ui.isError = false
      }).catch((e) => {
      this.ui.isError = true
      this.ui.error = this.getFirstErrorFromResponse(e.response)
    }).finally(() => {
      this.ui.loading = false
    })
  },
  methods: {
    updateLoan() {
      this.ui.isError = false;
      if (!this.loanNumber) {
        this.ui.isError = true;
        this.ui.error = 'Loan Number is required'
        return;
      }

      this.ui.form.loading = true;
      var formData = new FormData();
      formData.append('loanNumber', this.loanNumber);
      this.$http.post(`/api/v1/loans/uwm-loan/${this.loan.id}`, formData)
        .then((response) => {
          if (response.data.success) {
            this.alertSuccess(response.data.message);
          }
          else {
            this.alertError(response.data.message);
          }
        }).catch((e) => {
          this.alertError(this.getFirstErrorFromResponse(e.response))
      }).finally(() => {
        this.ui.form.loading = false;
      })
    },
    closeModal() {
      this.loanNumber = '';
      this.$emit('close');
    }
  },
  mounted() {
    const scrollbar = document.getElementById('modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar);
  }
}
</script>
<style lang="scss" scoped>
#modal-body-scroll {
  position: relative;
  height: calc(100% - 50px);
  margin-bottom: 20px;
}

#loanSyncInfo {
  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding: 6px 12px;

    &.pending {
      border: 1px solid #E7E8E8;
      border-radius: 8px;
      background-color: #F6F6F6;
      color: #000000;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
      margin-left: 12px;
    }
  }

  table {
    tr {
      td {
        padding: 10px 12px 10px 10px;
      }
    }
  }
  .text-right {
    text-align: right !important;
  }
}
</style>
